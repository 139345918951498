import React, { useState } from "react";
import { Layout, Button, Modal, Typography } from "antd";
import { LinkedinOutlined } from "@ant-design/icons";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <AntFooter style={{ textAlign: "center" }}>
        <Button type="link" onClick={showModal}>
          Privacy Policy
        </Button>

        <Text>2024. Made by Fedor GNETKOV</Text>

        <Button
          type="primary"
          icon={<LinkedinOutlined />}
          href="https://www.linkedin.com/in/gnetkov/"
          target="_blank"
          style={{ marginLeft: 10 }}
        >
          Hire me
        </Button>
      </AntFooter>

      <Modal
        title="Privacy Policy"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <p>
          We value your privacy. This application does not use cookies or track
          any personal data. We do not store any of your information on our
          servers. However, this application integrates with the OpenAI API to
          provide certain features, and while no personal data is shared
          directly with OpenAI, it's important to note that data submitted to
          the API for processing is handled according to OpenAI's privacy
          practices.
        </p>
        <p>
          For more details on how OpenAI handles data, please refer to the
          OpenAI Privacy Policy.
        </p>
      </Modal>
    </>
  );
};

export default Footer;
