import Footer from "../components/Footer";
import FormPage from "./FormPage";

const HomePage = () => {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div style={{           flex: 1,
          display: "flex",  }}>
        <FormPage />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
