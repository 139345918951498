import React, { useState } from 'react';
import { Select, Form, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons'; 

const { Option } = Select;

interface EmailSettingsProps {
  maxCharacters: number;
  setMaxCharacters: (value: number) => void;
  intonation: string;
  setIntonation: (value: string) => void;
}

const EmailSettings: React.FC<EmailSettingsProps> = ({
  maxCharacters,
  setMaxCharacters,
  intonation,
  setIntonation
}) => {
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false); // State to control visibility

  return (
    <div style={{ marginBottom: '20px' }}>
      <Button type="dashed" onClick={() => setSettingsVisible(!settingsVisible)}>
      <SettingOutlined 
          style={{ 
            transform: settingsVisible ? 'rotate(90deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s'
          }} 
        />{settingsVisible ? "Hide Settings" : "Show Settings"}
      </Button>
      {settingsVisible && (
        <Form layout="horizontal" style={{paddingTop: "15px"}}>
          <Form.Item label="Max Number of Characters">
            <Select
              value={maxCharacters}
              onChange={(value) => setMaxCharacters(value)}
              style={{ width: '100%' }}
            >
              <Option value={100}>100</Option>
              <Option value={150}>150</Option>
              <Option value={200}>200</Option>
              <Option value={250}>250</Option>
              <Option value={300}>300</Option>
              <Option value={350}>350</Option>
              <Option value={400}>400</Option>
              <Option value={500}>500</Option>
              <Option value={600}>600</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Intonation of Email">
            <Select
              value={intonation}
              onChange={(value) => setIntonation(value)}
              style={{ width: '100%' }}
            >
              <Option value="polite">👌 Polite</Option>
              <Option value="excited">🤩 Excited</Option>
              <Option value="friendly">🫂 Friendly</Option>
              <Option value="formal">👔 Formal</Option>
              <Option value="casual">👖 Casual</Option>
              <Option value="concise">🕵️ Concise</Option>
              <Option value="in love">🥰 In love</Option>
            </Select>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EmailSettings;