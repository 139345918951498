import HomePage from "./pages/HomePage";
import './App.css';
import 'typeface-inter';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
