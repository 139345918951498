import axios from "axios";
import {
  ResponseVariantsInput,
  ResponseVariantsOutput,
  ResponseEmailInput,
  ResponseEmailOutput,
} from "../types/emailTypes";

const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

// Create an Axios instance
const apiClient = axios.create({
  baseURL: backendUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Service function to generate response variants
export const createResponseVariants = async (
  data: ResponseVariantsInput
): Promise<ResponseVariantsOutput> => {
  try {
    const response = await apiClient.post<ResponseVariantsOutput>(
      "/response-variants",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error creating response variants", error);
    throw error;
  }
};

// Service function to generate an email
export const generateEmail = async (
  data: ResponseEmailInput
): Promise<ResponseEmailOutput> => {
  try {
    const response = await apiClient.post<ResponseEmailOutput>(
      "/response-email",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error generating email", error);
    throw error;
  }
};
