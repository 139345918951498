import React, { useState } from "react";
import { Button, Input, Form, message, Flex, Modal, Steps } from "antd";
// import openaiService from "../services/openaiService";
// import { ChatMessage } from "../types/openaiTypes";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CustomResponseModal from "./components/CustomResponseModal";
import EmailSettings from "./components/EmailSettings";
import {
  createResponseVariants,
  generateEmail,
} from "../services/emailService";

const { TextArea } = Input;

const ChatComponent: React.FC = () => {
  const [inputText, setInputText] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [completion, setCompletion] = useState<string[] | null>(null);
  const [generatedEmail, setGeneratedEmail] = useState<string | null>(null);
  const [loadingStep1, setLoadingStep1] = useState<boolean>(false);
  const [loadingStep2, setLoadingStep2] = useState<boolean>(false);
  const [infoModalVisible, setInfoModalVisible] = useState<boolean>(false);
  const [customResponseModalVisible, setCustomResponseModalVisible] =
    useState<boolean>(false);
  const [customResponseText, setCustomResponseText] = useState<string>("");
  const [maxCharacters, setMaxCharacters] = useState<number>(250);
  const [intonation, setIntonation] = useState<string>("polite");

  const handleSubmit = async () => {
    if (inputText.length < 20) {
      message.error("Text must be at least 20 characters long.");
      return;
    }

    if (inputText.length > 10000) {
      message.error("Text must be at max 10,000 characters long.");
      return;
    }

    setLoadingStep1(true);
    try {
      // const messages: ChatMessage[] = [
      //   { role: "system", content: "You are a helpful assistant." },
      //   {
      //     role: "system",
      //     content:
      //       "Analyse given email. Propose 5 responses. They should be different: positive, negative, more details, pricing, etc. Responses should be most likely response. It should be like action. Each response should have max 4 words. Give only text responses separated by |.",
      //   },
      //   { role: "user", content: inputText },
      // ];

      // const result = await openaiService.fetchCompletion(messages);
      const result = await createResponseVariants({ email: inputText });
      setCompletion(result.variants);
    } catch (error) {
      message.error("Failed to fetch completion.");
    } finally {
      setLoadingStep1(false);
      setStep(1);
    }
  };

  const handleSubmitGenerateEmail = async (responseIdea: string) => {
    setLoadingStep2(true);
    try {
      // const messages: ChatMessage[] = [
      //   { role: "system", content: "You are a helpful assistant." },
      //   {
      //     role: "system",
      //     content: `Analyse given email. Write a response for the given response idea. Response should be formatted. The response should have a maximum of ${maxCharacters} characters. The intonation of the email should be ${intonation}. Give only text of response and only body of response (no subject). If there is senders name in given email, it should start from senders name. The response should be formatted with line breaks. Each sentence should be on a new line. If the response requires a new paragraph, use two line breaks. Only final response ready to send.`,
      //   },
      //   { role: "user", content: `Given email: ${inputText}` },
      //   { role: "user", content: `Given response idea is '${responseIdea}'` },
      // ];

      // const result = await openaiService.fetchCompletion(messages);
      const result = await generateEmail({
        given_email: inputText,
        given_response_idea: responseIdea,
        max_number_characters: maxCharacters,
        intonation_of_email: intonation,
      });
      setGeneratedEmail(result.email);
    } catch (error) {
      message.error("Failed to fetch completion from OpenAI API.");
    } finally {
      setLoadingStep2(false);
      setStep(2);
    }
  };

  const handleCopyToClipboard = () => {
    if (generatedEmail) {
      navigator.clipboard
        .writeText(generatedEmail)
        .then(() => {
          message.success("Generated email copied to clipboard!");
        })
        .catch(() => {
          message.error("Failed to copy to clipboard.");
        });
    }
  };

  const showInfoModal = () => {
    setInfoModalVisible(true);
  };

  const handleInfoModalOk = () => {
    setInfoModalVisible(false);
  };

  const handleInfoModalCancel = () => {
    setInfoModalVisible(false);
  };

  const showCustomResponseModal = () => {
    setCustomResponseModalVisible(true);
  };

  const handleCustomResponseModalOk = () => {
    if (customResponseText.trim().length < 5) {
      message.error("Response must be at least 5 characters long.");
      return;
    }
    handleSubmitGenerateEmail(customResponseText);
    setCustomResponseModalVisible(false);
    setCustomResponseText("");
  };

  const handleCustomResponseModalCancel = () => {
    setCustomResponseModalVisible(false);
    setCustomResponseText("");
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Flex justify="space-between" align="flex-end">
        <h1 className="animated-gradient-text" style={{marginRight: "15px"}}>AI email responder</h1>
        <Button
          type="default"
          icon={<InfoCircleOutlined />}
          onClick={showInfoModal}
          style={{ float: "right", marginBottom: "20px" }}
        >
          Info
        </Button>
      </Flex>

      <Steps
        size="small"
        current={step}
        items={[
          {
            title: "Enter message text",
          },
          {
            title: "Choose response type",
          },
          {
            title: "Response generated!",
          },
        ]}
        style={{ marginBottom: "40px" }}
      />

      {!completion ? (
        <Form onFinish={handleSubmit}>
          <Form.Item>
            <TextArea
              rows={8}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Type message you recieved here (at least 20 characters)..."
            />
          </Form.Item>
          <Form.Item>
            <Flex justify="flex-end" align="center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                onClick={handleSubmit}
                loading={loadingStep1}
                disabled={inputText.length < 20}
              >
                <div style={{ padding: "20px" }}>Submit</div>
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <EmailSettings
            maxCharacters={maxCharacters}
            setMaxCharacters={setMaxCharacters}
            intonation={intonation}
            setIntonation={setIntonation}
          />
          <h3>Select a Response:</h3>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {completion.map((response, index) => (
              <Button
                size="large"
                key={index}
                type="default"
                style={{ whiteSpace: "normal" }}
                onClick={() => handleSubmitGenerateEmail(response.trim())}
                loading={loadingStep2 && generatedEmail === null}
              >
                {response.trim()}
              </Button>
            ))}
            {completion ? (
              <Button
                size="large"
                key={"custom_response"}
                type="default"
                style={{ whiteSpace: "normal" }}
                onClick={showCustomResponseModal}
                loading={loadingStep2 && generatedEmail === null}
              >
                Add your short response...
              </Button>
            ) : (
              <></>
            )}
          </div>
          {generatedEmail && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                background: "#f6f8fa",
                borderRadius: "5px",
              }}
            >
              <Flex justify="space-between" align="flex-start">
                <h3 style={{ margin: 0 }}>Generated Email: </h3>
                <Button
                  type="default"
                  icon={<CopyOutlined />}
                  onClick={handleCopyToClipboard}
                ></Button>
              </Flex>
              <p style={{ whiteSpace: "pre-wrap" }}>{generatedEmail}</p>
            </div>
          )}
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() => {
              setCompletion(null);
              setGeneratedEmail(null);
              setStep(0);
            }}
          >
            Go Back
          </Button>
        </div>
      )}
      <Modal
        title="How It Works"
        open={infoModalVisible}
        onOk={handleInfoModalOk}
        onCancel={handleInfoModalCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleInfoModalOk}>
            Got it
          </Button>,
        ]}
      >
        <p>1. Paste the email you want to respond to in the text area above.</p>
        <p>2. Click "Submit" to get five possible responses from the AI.</p>
        <p>3. Select the response that best suits your needs.</p>
        <p>
          4. The AI will generate a full email based on your selected response.
        </p>
        <p>5. Copy the generated email and send it to your recipient.</p>
      </Modal>
      <CustomResponseModal
        visible={customResponseModalVisible}
        onOk={handleCustomResponseModalOk}
        onCancel={handleCustomResponseModalCancel}
        responseText={customResponseText}
        setResponseText={setCustomResponseText}
        loading={loadingStep2}
      />
    </div>
  );
};

export default ChatComponent;
