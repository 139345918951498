
import React from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

interface CustomResponseModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  responseText: string;
  setResponseText: (text: string) => void;
  loading?: boolean;
}

const CustomResponseModal: React.FC<CustomResponseModalProps> = ({
  visible,
  onOk,
  onCancel,
  responseText,
  setResponseText,
  loading = false,
}) => {
  return (
    <Modal
      title="Add Your Custom Response"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Generate Email"
      confirmLoading={loading} 
      okButtonProps={{ disabled: responseText.trim().length < 5 }}
    >
      <TextArea
        rows={4}
        value={responseText}
        onChange={(e) => setResponseText(e.target.value)}
        placeholder="Type your short response here (at least 5 characters)..."
      />
    </Modal>
  );
};

export default CustomResponseModal;